<template>
  <div class="container">
    <button
      type="submit"
      class="btn btn-primary btn-sm float-end"
      data-bs-toggle="modal"
      data-bs-target="#modalNoticiasAdicionar"
      @click="resetModal()"
      v-if="userJson.permissao_sistema == 'adm' || userJson.permissao_sistema == 'marketing'"
      style="margin-right: 20px;"
    >
      Adicionar
    </button>
    <div style="text-align: center"> 
      <h3>Mural de Noticias</h3>
    </div>

    <div class="row justify-content-center container-responsive">
      <card-component
        v-for="n in noticias"
        :key="n.id"
        class="container-pricipal p-0 m-5 w-300px"
        :titulo="n.texto"
      >
        <template v-slot:conteudo>
          <div class="open-popup-link" v-if="n.imagem != ''">
            <a :href="'/storage/' + n.imagem" class="img">
              <img
                :src="'/storage/' + n.imagem"
                :alt="'Descrição ' + n.texto"
                width="180px"
                height="315px"
              />
            </a>
          </div>
        </template>
        <template v-slot:rodape v-if="userJson.permissao_sistema == 'adm' || userJson.permissao_sistema == 'marketing'">
          <button
            type="submit"
            class="btn btn-primary btn-sm float-end"
            data-bs-toggle="modal"
            data-bs-target="#modalNtociasEdit"
            @click="setStore(n)"
          >
            Editar
          </button>
          <button
            type="submit"
            class="btn btn-danger btn-sm float-end"
            style="margin-right: 10px"
            data-bs-toggle="modal"
            data-bs-target="#modalNoticiasRemove"
            @click="setStore(n)"
          >
            Excluir
          </button>
        </template>
      </card-component>
    </div>
    <!-- </div> -->
    <!-- ########################################## -->
    <!-- Inicio modal adicionar vagas -->
    <modal-component id="modalNoticiasAdicionar" titulo="Adicionar noticia">
      <template v-slot:alertas>
        <alert-component
          mensagem="Transação realizada com sucesso"
          tipo="success"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"
        ></alert-component>
        <alert-component
          mensagem="Erro na transação"
          tipo="danger"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"
        ></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="Texto da postagem"
                id="inputTexto"
                id-help="textoHelp"
                texto-ajuda="Informe texto da postagem"
              >
                <hr />
                <input
                  type="text"
                  class="form-control"
                  name="texto"
                  id="inputTexto"
                  aria-describedby="textoHelp"
                  placeholder="Informe texto da postagem"
                  v-model="texto"
                />
              </input-container-component>
            </div>
          </div>
          <div class="col mb-3">
            <div class="form-group">
              <div id="preview">
                <img
                  v-if="urlPreview"
                  :src="urlPreview"
                  width="150"
                  height="200"
                />
              </div>
              <input-container-component
                titulo="Selecione a imagem"
                id="inputImagem"
                id-help="imagemHelp"
                texto-ajuda="Selecione a imagem"
              >
                <hr />
                <input
                  type="file"
                  name="imagem"
                  class="form-control"
                  id="inputImagem"
                  aria-describedby="imagemHelp"
                  placeholder="Selecione uma imagem"
                  @change="carregarImagem($event)"
                />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="salvar()">
          Salvar
        </button>
      </template>
    </modal-component>
    <!-- Fim modal adiocionar notiicia -->
    <!-- ########################################## -->
    <!-- Inicio modal remover notiicia -->
    <modal-component id="modalNoticiasRemove" titulo="Remover noticia">
      <template v-slot:alertas>
        <alert-component
          mensagem="Transação realizada com sucesso"
          tipo="success"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"
        ></alert-component>
        <alert-component
          mensagem="Erro na Transação"
          tipo="danger"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"
        ></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="Texto da postagem"
                id="inputTexto"
                id-help="textoHelp"
                texto-ajuda="Texto da postagem"
              >
                <hr />
                <input
                  type="text"
                  class="form-control"
                  name="texto"
                  id="inputTexto"
                  aria-describedby="textoHelp"
                  disabled
                  :value="$store.state.item.texto"
                />
              </input-container-component>
            </div>
          </div>
          <div class="col mb-3">
            <div class="form-group">
              <img
                :src="'/storage/' + $store.state.item.imagem"
                width="200"
                height="400"
                v-if="$store.state.item.imagem"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="remover()"
          v-if="$store.state.transacao.status != 'sucesso'"
        >
          Remover
        </button>
      </template>
    </modal-component>
    <!-- ########################################## -->
    <!-- Inicio modal atualizar vagas -->
    <modal-component
      id="modalNtociasEdit"
      titulo="Atualizar noticia"
      v-if="$store.state.item"
    >
      <template v-slot:alertas>
        <alert-component
          mensagem="Transação realizada com sucesso"
          tipo="success"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"
        ></alert-component>
        <alert-component
          mensagem="Erro na transação"
          tipo="danger"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"
        ></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="Texto da postagem"
                id="inputTexto"
                id-help="textoHelp"
                texto-ajuda="Texto da postagem"
              >
                <hr />
                <input
                  type="text"
                  class="form-control"
                  name="texto"
                  id="inputTexto"
                  aria-describedby="textoHelp"
                  v-model="$store.state.item.texto"
                />
              </input-container-component>
            </div>
          </div>
          <div class="col mb-3">
            <div class="form-group">
              <div id="preview">
                <img
                  v-if="urlPreview"
                  :src="urlPreview"
                  width="150"
                  height="200"
                />
                <img
                  v-if="$store.state.item.imagem != null && urlPreview == ''"
                  :src="'/storage/' + $store.state.item.imagem"
                  width="150"
                  height="200"
                />
              </div>
              <input-container-component
                titulo="Selecione a imagem"
                id="inputImagem"
                id-help="imagemHelp"
                texto-ajuda="Selecione a imagem"
              >
                <hr />
                <input
                  type="file"
                  name="imagem"
                  class="form-control"
                  id="inputImagem"
                  aria-describedby="imagemHelp"
                  placeholder="Selecione uma imagem"
                  @change="carregarImagem($event)"
                />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="atualizar()">
          Atualizar
        </button>
      </template>
    </modal-component>
    <!-- Fim modal atualizar vagas -->
    <!-- ########################################## -->
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      urlBase: "https://portalcolaborador.candido.org.br/api/v1/noticia",
      imagem: [],
      transacaoStatus: "",
      transacaoDetalhes: {},
      noticias: [],
      texto: "",
      urlPreview: "",
    };
  },
  methods: {
    resetModal() {
      this.$store.state.item = {};
      this.$store.state.transacao.status = "";
      this.$store.state.transacao.mensagem = "";
      this.texto = "";
      this.imagem = [];
      this.urlPreview = "";
      inputImagem[0].value = "";
    },
    atualizar() {
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("texto", this.$store.state.item.texto);
      if (this.imagem[0] != null && this.imagem[0] != undefined) {
        formData.append("imagem", this.imagem[0]);
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      let url = this.urlBase + "/" + this.$store.state.item.id;

      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarNoticias();
        })
        .catch((errors) => {
          this.setMessage( 
            "erro",
            errors.response.data.message,
            errors.response.data.errors
          );
        });
    },
    remover() {
      let confirmacao = confirm("Confirmar remoção do registro?");
      if (!confirmacao) return false;
      let url = this.urlBase + "/" + this.$store.state.item.id;
      let formData = new FormData();
      formData.append("_method", "delete");
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          this.carregarNoticias();
          this.setMessage("sucesso", response.data.msg);
        })
        .catch((errors) => {
          this.setMessage( 
            "erro",
            errors.response.data.message,
            errors.response.data.errors
          );
        });
    },
    setStore(obj) {
      this.resetModal();
      this.$store.state.item = obj;
      this.$store.state.transacao.status = "";
      this.$store.state.transacao.mensagem = "";
    },
    carregarNoticias() {
      axios
        .get(this.urlBase)
        .then((response) => {
          this.noticias = response.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    carregarImagem(e) {
      this.imagem = e.target.files;
      this.urlPreview = URL.createObjectURL(this.imagem[0]);
    },
    salvar() {
      let formData = new FormData();
      formData.append("texto", this.texto);
      formData.append("imagem", this.imagem[0]);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios
        .post(this.urlBase, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarNoticias();
          this.resetModal()
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
  },
  mounted() {
    this.carregarNoticias();
  },
  computed: {
    userJson() {
      return JSON.parse(this.user);
    },
  },
};
</script>
