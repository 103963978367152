var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{attrs:{"id":"cestaBasica"}},[_c('div',{staticStyle:{"border":"1px solid","background-color":"#fff"},attrs:{"id":"imgcestabasica"}},[_c('div',{staticStyle:{"border":"1px solid","margin":"15px","background-color":"#fff"}},[_c('thead',[_c('tr',[_vm._m(0),_vm._v(" "),_c('th',[_c('span',{staticStyle:{"float":"right","padding-right":"5px"}},[_vm._v(" REF: "+_vm._s(_vm.mesref))])])])]),_vm._v(" "),_c('tbody',[_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Nome: "+_vm._s(_vm.nome))])]),_vm._v(" "),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("\n            Período de retirada da cesta básica é de\n            "+_vm._s(_vm._f("formataDataGlobal")(_vm.datacesta.data_inicio))+" a\n            "+_vm._s(_vm._f("formataDataGlobal")(_vm.datacesta.data_fim))),_c('br'),_vm._v("\n            Local de Retirada: Rua Maria Soares, 225 - Vila\n            Industrial-Campinas -SP."),_c('br'),_vm._v(" "),_c('b',[_vm._v("Para a retirada da cesta básica será obrigatório a apresentação\n              deste vale e documento com foto.")])])]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('center',[_c('img',{attrs:{"src":"assinatura.png","width":"150px","height":"100px"}})])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('img',{staticStyle:{"float":"left"},attrs:{"src":"logo.jpg","width":"75px","height":"50px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("SERVICO DE SAUDE DR CANDIDO FERREIRA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("C E S T A   B Á S I C A")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v("HORÁRIO DE RETIRADA")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("\n            SEGUNDA A SEXTA-FEIRA - 08:00 AS 18:00 HORAS"),_c('br'),_vm._v("\n            SÁBADO DAS 08h00 ÁS 12h00 "),_c('br'),_vm._v(" "),_c('b',[_vm._v("Telefones para entregas:")]),_vm._v(" (19) 3365-1577 / (19) 3365-1477"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("\n            A cesta básica não será entregue fora do prazo determinado\n            acima."),_c('br')])])
}]

export { render, staticRenderFns }