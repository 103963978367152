<template>
  <div class="container">
    <button
      type="submit"
      class="btn btn-primary btn-sm float-end"
      data-bs-toggle="modal"
      data-bs-target="#modalRelogioAdicionar"
      @click="resetModal()"
      style="margin-right: 20px;"
    >
      Adicionar
    </button>
    <div class="row justify-content-center w-100">
      <div class="col-md-8 m-1" v-for="relogio in relogios" :key="relogio.id">
        <div class="card">
          <div class="card-header" style="text-align: center"></div>

          <div class="card-body" style="padding: 0 15px">
            <div class="row">
              <div class="col-6">
                <div class="lnr">
                  <b class="m-2">Local: </b>
                  <p class="m-2">{{ relogio.local }}</p>
                </div>
                <div class="lnr">
                  <b class="m-2">URL: </b>
                  <p class="m-2">{{ relogio.url }}</p>
                </div>
                <div class="lnr">
                  <b class="m-2">Login: </b>
                  <p class="m-2">{{ relogio.login }}</p>
                </div>
                <div class="lnr">
                  <b class="m-2">Password: </b>
                  <p class="m-2">{{ relogio.password }}</p>
                </div>
              </div>
              <div class="col-6 log_rlg">
                <b>Log criação do arquivo AFD</b>
                <hr />
                <span v-for="log in relogio.log_ponto" :key="log.id">
                    <span>Data: {{log.data}}</span><br>
                    <span>Hora: {{log.hora}}</span><br>
                    <span>Mensagem: {{log.mensagem}}</span>
                    <hr>
                </span>
              </div>
            </div>
          </div>
          <div class="card-footer" style="text-align: center">
            <button
              type="submit"
              class="btn btn-primary btn-sm float-end"
              data-bs-toggle="modal"
              data-bs-target="#modalRelogioEdit"
              @click="setStore(relogio)"
            >
              Editar
            </button>
            <button
              type="submit"
              class="btn btn-danger btn-sm float-end"
              style="margin-right: 10px"
              data-bs-toggle="modal"
              data-bs-target="#modalRelogioRemove"
              @click="setStore(relogio)"
            >
              Excluir
            </button>
          </div>
        </div>
      </div>
      <!-- #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-#
                   Inicio  Modal Adicionar
      #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-# -->
      <modal-component id="modalRelogioAdicionar" titulo="Adicionar relogio">
        <template v-slot:alertas>
          <alert-component
            mensagem="Transação realizada com sucesso"
            tipo="success"
            :detalhes="$store.state.transacao"
            v-if="$store.state.transacao.status == 'sucesso'"
          ></alert-component>
          <alert-component
            mensagem="Erro na transação"
            tipo="danger"
            :detalhes="$store.state.transacao"
            v-if="$store.state.transacao.status == 'erro'"
          ></alert-component>
        </template>
        <template v-slot:conteudo>
          <div>
            <!-- <div class="col mb-3" style=""> -->
              <input-container-component
              titulo="Local"
              id="InputLocal"
              id-help="LocalHelp"
              texto-ajuda="Informe a URL"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="local"
                id="InputLocal"
                aria-describedby="LocalHelp"
                placeholder="Informe o local"
                v-model="relogioAdd.local"
              />
            </input-container-component>
            <input-container-component
              titulo="IP relogio ponto ex: https://192.168.0.1"
              id="inputIp"
              id-help="IpHelp"
              texto-ajuda="Informe a URL"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="url"
                id="inputIp"
                aria-describedby="IpHelp"
                placeholder="https://"
                v-model="relogioAdd.url"
              />
            </input-container-component>
            <input-container-component
              titulo="Usuario"
              id="inputUsuario"
              id-help="usuarioHelp"
              texto-ajuda="Informe usuario"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="texto"
                id="inputUsuario"
                aria-describedby="usuarioHelp"
                placeholder="Informe usuario"
                v-model="relogioAdd.login"
                value="http://"
              />
            </input-container-component>
            <input-container-component
              titulo="Senha"
              id="inputSenha"
              id-help="senhaHelp"
              texto-ajuda="Informe a senha"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="texto"
                id="inputSenha"
                aria-describedby="senhaHelp"
                placeholder="Informe a senha"
                v-model="relogioAdd.password"
              />
            </input-container-component>
            <!-- </div> -->
          </div>
        </template>
        <template v-slot:rodape>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Fechar
          </button>
          <button type="button" class="btn btn-primary" @click="salvar()">
            Salvar
          </button>
        </template>
      </modal-component>
      <!-- #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-#
                   Fim  Modal Adicionar
      #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-# -->
      <!-- #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-#
                   Inicio  Modal Atualizar
      #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-# -->
      <modal-component id="modalRelogioEdit" titulo="Editar relogio">
        <template v-slot:alertas>
          <alert-component
            mensagem="Transação realizada com sucesso"
            tipo="success"
            :detalhes="$store.state.transacao"
            v-if="$store.state.transacao.status == 'sucesso'"
          ></alert-component>
          <alert-component
            mensagem="Erro na transação"
            tipo="danger"
            :detalhes="$store.state.transacao"
            v-if="$store.state.transacao.status == 'erro'"
          ></alert-component>
        </template>
        <template v-slot:conteudo>
          <div>
            <!-- <div class="col mb-3" style=""> -->
              <input-container-component
              titulo="Local"
              id="InputLocal"
              id-help="LocalHelp"
              texto-ajuda="Informe a URL"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="local"
                id="InputLocal"
                aria-describedby="LocalHelp"
                placeholder="Informe o local"
                v-model="$store.state.item.local"
              />
            </input-container-component>
            <input-container-component
              titulo="IP relogio ponto ex: https://192.168.0.1"
              id="inputIp"
              id-help="IpHelp"
              texto-ajuda="Informe a URL"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="url"
                id="inputIp"
                aria-describedby="IpHelp"
                placeholder="https://"
                v-model="$store.state.item.url"
              />
            </input-container-component>
            <input-container-component
              titulo="Usuario"
              id="inputUsuario"
              id-help="usuarioHelp"
              texto-ajuda="Informe usuario"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="texto"
                id="inputUsuario"
                aria-describedby="usuarioHelp"
                placeholder="Informe usuario"
                v-model="$store.state.item.login"
              />
            </input-container-component>
            <input-container-component
              titulo="Senha"
              id="inputSenha"
              id-help="senhaHelp"
              texto-ajuda="Informe a senha"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="texto"
                id="inputSenha"
                aria-describedby="senhaHelp"
                placeholder="Informe a senha"
                v-model="$store.state.item.password"
              />
            </input-container-component>
            <!-- </div> -->
          </div>
        </template>
        <template v-slot:rodape>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Fechar
          </button>
          <button type="button" class="btn btn-primary" @click="atualizar()">
            Atualizar
          </button>
        </template>
      </modal-component>
      <!-- #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-#
                   Fim  Modal Atualizar
      #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-# -->
      <!-- #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-#
                   Inicio  Modal Remover
      #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-# -->
      <modal-component id="modalRelogioRemove" titulo="Remover relogio">
        <template v-slot:alertas>
          <alert-component
            mensagem="Transação realizada com sucesso"
            tipo="success"
            :detalhes="$store.state.transacao"
            v-if="$store.state.transacao.status == 'sucesso'"
          ></alert-component>
          <alert-component
            mensagem="Erro na transação"
            tipo="danger"
            :detalhes="$store.state.transacao"
            v-if="$store.state.transacao.status == 'erro'"
          ></alert-component>
        </template>
        <template v-slot:conteudo>
          <div>
            <!-- <div class="col mb-3" style=""> -->
              <input-container-component
              titulo="Local"
              id="InputLocal"
              id-help="LocalHelp"
              texto-ajuda="Informe a URL"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="local"
                id="InputLocal"
                aria-describedby="LocalHelp"
                placeholder="Informe o local"
                v-model="$store.state.item.local"
                disabled
              />
            </input-container-component>
            <input-container-component
              titulo="IP relogio ponto ex: https://192.168.0.1"
              id="inputIp"
              id-help="IpHelp"
              texto-ajuda="Informe a URL"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="url"
                id="inputIp"
                aria-describedby="IpHelp"
                placeholder="https://"
                v-model="$store.state.item.url"
                disabled
              />
            </input-container-component>
            <input-container-component
              titulo="Usuario"
              id="inputUsuario"
              id-help="usuarioHelp"
              texto-ajuda="Informe usuario"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="login"
                id="inputUsuario"
                aria-describedby="usuarioHelp"
                placeholder="Informe usuario"
                v-model="$store.state.item.login"
                disabled
              />
            </input-container-component>
            <input-container-component
              titulo="Senha"
              id="inputSenha"
              id-help="senhaHelp"
              texto-ajuda="Informe a senha"
              class="mb-2"
            >
              <input
                type="text"
                class="form-control"
                name="password"
                id="inputSenha"
                aria-describedby="senhaHelp"
                placeholder="Informe a senha"
                v-model="$store.state.item.password"
                disabled
              />
            </input-container-component>
            <!-- </div> -->
          </div>
        </template>
        <template v-slot:rodape>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Fechar
          </button>
          <button type="button" class="btn btn-danger" @click="remover()" v-if="$store.state.transacao.status != 'sucesso'">
            Remover
          </button>
        </template>
      </modal-component>
      <!-- #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-#
                   Fim  Modal Remover
      #-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-##-#-#-#-#-#-# -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: "https://portalcolaborador.candido.org.br/api/v1/relogio_ponto", 
      relogios: {},
      relogioAdd: {
        url: "",
        login: "",
        password: "",
        local: ""
      },
    };
  },
  methods: {
    remover() {
      let confirmacao = confirm("Confirmar remoção do registro?");
      if (!confirmacao) return false;
      let url = this.baseUrl + "/" + this.$store.state.item.id;
      let formData = new FormData();
      formData.append("_method", "delete");
      let config = {
        headers: {
          "Content-Type": "x-www.form-urlencoded",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios 
        .post(url, formData, config)
        .then((response) => {
          this.getRelogios();
          this.setMessage("sucesso", response.data.msg);
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    atualizar() {
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("url", this.$store.state.item.url);
      formData.append("login", this.$store.state.item.login);
      formData.append("password", this.$store.state.item.password);
      formData.append("local", this.$store.state.item.local); 

      let config = {
        headers: {
          "Content-Type": "x-www.form-urlencoded",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      let url = this.baseUrl + "/" + this.$store.state.item.id;

      axios
        .post(url, formData, config)
        .then((response) => {
          this.getRelogios();
          this.setMessage("sucesso", response.data.msg);
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    salvar() {
      let formData = new FormData();
      formData.append("url", this.relogioAdd.url);
      formData.append("login", this.relogioAdd.login);
      formData.append("password", this.relogioAdd.password);
      formData.append("local", this.relogioAdd.local);
      let config = {
        headers: {
          "Content-Type": "x-www.form-urlencoded",
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(this.baseUrl, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.getRelogios();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    getRelogios() {
      axios.get(this.baseUrl).then((response) => {
        this.relogios = response.data;
      });
    },
    setStore(obj) {
      this.$store.state.item = obj;
    },
    resetModal() {
      this.relogioAdd = {};
    },
  },
  mounted() {
    this.getRelogios();
  },
};
</script>
<style>
.lnr {
  display: flex;
}
.log_rlg {
  border-left: 1px solid;
  padding: 5px;
  top: 0;
  max-height: 200px;
  overflow: auto;
}
</style>
