<template>
  <div class="container p-0 m-0">
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100" style="width: 30rem; background-color: #f4f5f8">
          <form
            method="post"
            action=""
            @submit.prevent="login($event)"
            class="login100-form validate-form"
          >
            <input type="hidden" name="_token" :value="csrf_token" />
            <center><img src="logo.jpg" width="300" height="180" /></center>
            <alert-component
              mensagem="Transação realizada com sucesso"
              tipo="success"
              :detalhes="$store.state.transacao"
              v-if="$store.state.transacao.status == 'sucesso'"
            ></alert-component>
            <alert-component
              mensagem="Erro na transação"
              tipo="danger"
              :detalhes="$store.state.transacao"
              v-if="$store.state.transacao.status == 'erro'"
            ></alert-component>
            <div class="row d-flex justify-content-center mt-3">
              <b><label for="Login" class="form-label">Login</label></b>
            </div>
            <div class="wrap-input100 validate-input">
              <input
                id="registro"
                type="text"
                class="input100"
                name="registro"
                value=""
                required
                autocomplete="registro"
                autofocus
                v-model="registro"
                placeholder="Matricula"
              />
            </div>

            <div class="row d-flex justify-content-center">
              <b><label for="Password" class="form-label">Password</label></b>
            </div>
            <div class="wrap-input100 validate-input">
              <input
                id="password"
                type="password"
                class="input100"
                name="password"
                required
                autocomplete="current-password"
                v-model="password"
                placeholder="Senha"
              />
            </div>

            <div class="container-login100-form-btn">
              <div class="wrap-login100-form-btn">
                <div class="login100-form-bgbtn"></div>
                <button class="login100-form-btn" type="submit">Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["csrf_token"],
  data() {
    return {
      registro: "",
      password: "",
    };
  },
  methods: {
    login(e) {
      let url = "https://portalcolaborador.candido.org.br/api/login";
      let cred = new URLSearchParams({
        registro: this.registro,
        password: this.password,
      });
      let configuracao = {
        method: "post",
        // headers: { Accept: "application/json", "Content-Type": "application/json" },
        body: cred,
      };
      fetch(url, configuracao)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          this.setMessage(
            "erro",
            "Usuário ou senha inválido!"
          );

        })
        .then((data) => {
          if (data.token) {
            document.cookie = "token=" + data.token + ";SameSite=Lax";
            e.target.submit();
          }
        });
    },
  },
};
</script>
