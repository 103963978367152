<template>
  <div class="card mb-3">
    <div class="card-header">
      <div class="row">
        <div class="col-12 text-center" id="content">{{ titulo }}</div>
      </div>
      <div class="row">
        <slot name="indicativos"></slot>
      </div>
      <div class="row">
        <slot name="pesquisa"></slot>
      </div>
      <div class="row justify-content-end">
          <slot name="btnAdd"></slot>
      </div>
      <slot name="emailCipa"></slot>
      <p class="m-0 p-0 form-text text-muted" v-if="data_limite">
        Expira em: {{ data_limite | formataDataGlobal }}
      </p>
    </div>
    <div class="card-body p-0">
      <slot name="conteudo"></slot>
    </div>
    <div class="card-footer">
      <slot name="rodape"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["titulo", "data_limite"],
  mounted() {
    $(".open-popup-link").magnificPopup({
      delegate: "a",
      type: "image",
    });
    function linkify(text) {
        // Expressão regular para identificar URLs que começam com http ou https
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        // Substituir o URL pelo texto do link
        return text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
    }

    // Obter o conteúdo da div e substituir URLs por links
    const contentDiv = document.getElementById('content');
    contentDiv.innerHTML = linkify(contentDiv.innerHTML);
  },
};
</script>
