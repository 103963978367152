<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <card-component titulo="Perfil">
          <template v-slot:conteudo>
            <div class="cont-body">
              <alert-component
                mensagem="Transação realizada com sucesso"
                tipo="success"
                :detalhes="$store.state.transacao"
                v-if="$store.state.transacao.status == 'sucesso'"
              ></alert-component>
              <alert-component
                mensagem="Erro na transação"
                tipo="danger"
                :detalhes="$store.state.transacao"
                v-if="$store.state.transacao.status == 'erro'"
              ></alert-component>
              <input-container-component titulo="">
                <label class="custom-file-upload float-end">
                  <input
                    type="file"
                    style="display: none"
                    @change="carregarImagem($event)"
                    id="inputImagem"
                    name="imagem"
                  />
                  <a class="btn btn-outline-primary btn-sm d-inline" > Foto perfil </a>
                </label>
                <div class="position-relative">
                  <img
                    v-if="urlPreview"
                    :src="urlPreview"
                    width="180"
                    height="180"
                    style="border-radius: 100%; object-fit: cover;"
                    class="mx-auto d-block"
                  />
                  <img
                    v-else-if="usuario.imagem != null && usuario.imagem != 'NULL' && usuario.imagem != 'null'" 
                    :src="'/storage/' + usuario.imagem"
                    width="180"
                    height="180"
                    style="border-radius: 100%; object-fit: cover;"
                    class="mx-auto d-block"
                  />
                  <img
                    v-else
                    src="perfil_padrao.jpg"
                    width="180"
                    height="180"
                    style="border-radius: 100%; object-fit: cover;"
                    class="mx-auto d-block"
                  />
                </div>
              </input-container-component>
              <div class="p-5">
                <input-container-component titulo="Registro">
                  <input
                    type="text"
                    class="form-control"
                    :value="usuario.registro"
                    disabled
                  />
                </input-container-component>
                <input-container-component titulo="Nome">
                  <input
                    type="text"
                    class="form-control"
                    v-model="usuario.name"
                  />
                </input-container-component>
                <input-container-component titulo="Data de Nascimento">
                  <input
                    type="date"
                    class="form-control"
                    name="nascimento"
                    id="inputEncerramento"
                    aria-describedby="EncerramentoHelp"
                    :max="dtMax"
                    v-model="usuario.nascimento"
                  />
                </input-container-component>
                <input-container-component titulo="Nova senha">
                  <input
                    type="password"
                    class="form-control"
                    v-model="usuario.password"
                  />
                </input-container-component>
                <input-container-component titulo="Confirmar senha">
                  <input
                    type="password"
                    class="form-control"
                    v-model="usuario.password_confirmation"
                  />
                </input-container-component>
              </div>
            </div>
          </template>
          <template v-slot:rodape>
            <button
              type="submit"
              class="btn btn-primary btn-sm float-end"
              @click="salvar()"
            >
              salvar
            </button>
          </template>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      urlBase: "https://portalcolaborador.candido.org.br/api/v1/usuarios/",
      programacao: "",
      imagem: [],
      usuario: {},
      urlPreview: "",
      dtMax: "",
    };
  },
  methods: {
    carregarImagem(e) {
      this.imagem = e.target.files;
      this.urlPreview = URL.createObjectURL(this.imagem[0]);
      console.log(this.imagem);
    },
    salvar() {
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("registro", this.usuario.registro);
      if (this.imagem[0]) {
        formData.append("imagem", this.imagem[0]);
      }
      formData.append("name", this.usuario.name);
      formData.append("nascimento", this.usuario.nascimento);
      if (this.usuario.password) {
        formData.append("password", this.usuario.password);
        formData.append(
          "password_confirmation",
          this.usuario.password_confirmation
        );
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios
        .post(this.urlBase + this.usuario.id, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.getUsuarios();
        })
        .catch((errors) => {
          this.setMessage( 
            "erro",
            errors.response.data.message,
            errors.response.data.errors
          );
        });
    },
    getUsuarios() {
      axios
        .get(this.urlBase + this.userToJson.id)
        .then((response) => {
          this.usuario = response.data;
          this.usuario.nascimento = response.data.nascimento.split(" ")[0];
        })
        .catch((errors) => {
          console.log(errors.response);
        });
    },
    formatNascimento() {
      this.userToJson.nascimento = this.userToJson.nascimento.split(" ")[0];
      this.dtMax =
        new Date().getFullYear() -
        16 +
        "-" +
        new Date().getMonth().toString().padStart(2, "0") +
        "-" +
        new Date().getDate().toString().padStart(2, "0");
    },
  },
  mounted() { 
    this.formatNascimento();
  },
  computed: {
    userToJson() {
      return (this.usuario = JSON.parse(this.user));
    },
  },
};
</script>

<style>
.cont-body {
  padding: 20px;
}

@media screen and (max-width: 450px) {
  .cont-body {
  padding: 5px;
}
}
</style>