<template>
  <div class="containerBtn">
    <div id="cestaBasica" class="white-popup mfp-hide" v-if="periodoCesta">
      <button type="submit" @click="downloadCestaBasica('imgcestabasica')" class="bntDownload"
        style="background: #FFF;">
        <i class="bx bxs-download"></i>
        Download
      </button>
      <cesta-basica-component :nome="nome" :mesref="mesref" :datacesta="datacesta"></cesta-basica-component>
    </div>
    <div id="cestaBasica" class="white-popup mfp-hide" v-else>
      <div style="border: 1px solid; margin: 15px; background-color: #fff">
        <thead>
          <tr>
            <th>
              <img style="float: left" src="logo.jpg" width="100px" height="75px" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>SERVICO DE SAUDE DR CANDIDO FERREIRA</td>
          </tr>
          <tr>
            <td>Nome: {{ nome }}</td>
          </tr>
          <tr>
            <td>
              <b>Vale-Cesta Indisponível no momento</b>
            </td>
          </tr>
        </tbody>
      </div>
    </div>
    <div id="cestaPremium" class="white-popup mfp-hide" v-if="periodoCesta && permissaocesta == 1">
      <button type="submit" @click="downloadCestaBasica('imgcestapremium')" class="bntDownload"
        style="background: #FFF;">
        <i class="bx bxs-download"></i>
        Download
      </button>
      <cesta-premium-component :nome="nome" :mesref="mesref" :datacesta="datacesta"
        :permissaocesta="permissaocesta"></cesta-premium-component>
    </div>
    <div id="cestaPremium" class="white-popup mfp-hide" v-else>
      <div style="border: 1px solid; margin: 15px; background-color: #fff">
        <thead>
          <tr>
            <th>
              <img style="float: left" src="logo.jpg" width="100px" height="75px" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>SERVICO DE SAUDE DR CANDIDO FERREIRA</td>
          </tr>
          <tr>
            <td>Nome: {{ nome }}</td>
          </tr>
          <tr>
            <td>
              <b>Vale-Cesta Indisponível no momento</b>
            </td>
          </tr>
        </tbody>
      </div>
    </div>
    
      <a href="#cestaBasica" class="open-popup-image btn btn-primary btn-principal m-2">Cesta Básica</a>
      <a href="#cestaPremium" class="open-popup-image btn btn-primary btn-principal m-2">Cesta Premium</a>
    
    <!-- <div class="w-100">
      <a :href="'irrf/' + registroUser + '.pdf'" class="btn btn-primary btn-principal m-2" target="_blank">IRRF</a> <br />
      <a :href="'convenios/' + registroUser + '.pdf'" class="btn btn-primary btn-principal m-2" target="_blank">CONVENIOS</a>
     </div> -->
  </div>
</template>

<script>
export default {
  props: ["nome", "datacesta", "permissaocesta", "registroUser"],
  data() {
    return {
      mesref: ""
    };
  },
  methods: {
    getMesRef() {
      const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Junlho",
        "Agostro",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      const d = new Date();
      this.mesref = months[d.getMonth()] + "/" + d.getFullYear();
    },
    downloadCestaBasica(element) {
      console.log(element);
      domtoimage.toBlob(document.getElementById(element)).then(function (blob) {
        window.saveAs(blob, "cesta.png");
      });
    },
  },
  mounted() {
    this.getMesRef();
    $(".open-popup-image").magnificPopup({
      type: "inline",
      //   midClick: true,
      //   closeOnBgClick: true
      //   closeBtnInside: true,
      //   showCloseBtn: true
      //   closeOnContentClick: true
      //   alignTop: true
    });
  },
  computed: {
    periodoCesta() {
      if (Date.parse(this.datacesta.data_inicio) <= Date.parse(new Date()) && Date.parse(this.datacesta.data_fim) > new Date().setDate(new Date().getDate() - 1)) {
        return true
      }
      return false
    }
  }
};
</script>
<style>
.mfp-close {
  color: #fff !important;
}

.containerBtn {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.containerBtn a {
  font-size: 18px;
}

.bntDownload {
  position: absolute;
  margin: 10px 10px;
  left: 20px;
}

#cestaPremium {
  display: flex;
  justify-content: center;
}

#cestaBasica {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .bntDownload {
    position: absolute;
    margin: 0;
    left: 0;
  }

  #cestaPremium {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }

  #cestaBasica {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }

  .containerBtn a {
    font-size: 12px;
  }
}
</style>