<template>
  <div class="container">
    <div style="text-align: center">
      <h3>Vagas Interna</h3>
    </div>
    <button type="submit" class="btn btn-primary btn-sm float-end m-3" data-bs-toggle="modal" data-bs-target="#modalVagas"
      @click="resetModal()" v-if="user == 'adm'" style="margin-right: 20px;">
      Adicionar
    </button>

    <div class="row justify-content-center container-responsive">
      <card-component v-for="v in vagas" :key="v.id" class="container-vaga p-0 mt-3" :titulo="v.titulo"
        :data_limite="v.programacao" style="margin: 0 auto;">
        <template v-slot:conteudo>
          <div class="open-popup-link">
            <a :href="'/storage/' + v.imagem" class="img">
              <img :src="'/storage/' + v.imagem" :alt="'Processo seletivo n° ' + v.id" width="180px" height="320px" />
            </a>
          </div>
        </template>
        <template v-slot:rodape v-if="user == 'adm'">
          <button type="submit" class="btn btn-primary btn-sm float-end" data-bs-toggle="modal"
            data-bs-target="#modalVagasEdit" @click="setStore(v)">
            Editar
          </button>
          <button type="submit" class="btn btn-danger btn-sm float-end" style="margin-right: 10px" data-bs-toggle="modal"
            data-bs-target="#modalVagasRemove" @click="setStore(v)">
            Excluir
          </button>
        </template>
      </card-component>
    </div>
    <!-- </div> -->
    <!-- ########################################## -->
    <!-- Inicio modal adicionar vagas -->
    <modal-component id="modalVagas" titulo="Adicionar vaga">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component titulo="Titulo da vaga" id="inputTitulo" id-help="tituloHelp"
                texto-ajuda="Informe titulo da vaga">
                <hr />
                <input type="text" class="form-control" name="titulo" id="inputTitulo" aria-describedby="tituloHelp"
                  placeholder="Informe titulo da vaga" v-model="titulo" />
              </input-container-component>
              <input-container-component titulo="Agendar data de encerramento" id="inputEncerramento"
                id-help="EncerramentoHelp" texto-ajuda="Informe a data do encerramento">
                <hr />
                <input type="date" class="form-control" name="programacao" id="inputEncerramento"
                  aria-describedby="EncerramentoHelp" :min="dataDay" v-model="programacao" />
              </input-container-component>
            </div>
          </div>
          <div class="col mb-3">
            <div class="form-group">
              <div id="preview">
                <img v-if="urlPreview" :src="urlPreview" width="150" height="200" />
              </div>
              <input-container-component titulo="Selecione a imagem" id="inputImagem" id-help="imagemHelp"
                texto-ajuda="Selecione a imagem">
                <hr />
                <input type="file" name="imagem" class="form-control" id="inputImagem" aria-describedby="imagemHelp"
                  placeholder="Selecione uma imagem" @change="carregarImagem($event)" />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="salvar()">
          Salvar
        </button>
      </template>
    </modal-component>
    <!-- Fim modal adiocionar vagas -->
    <!-- ########################################## -->
    <!-- Inicio modal remover vagas -->
    <modal-component id="modalVagasRemove" titulo="Remover vaga">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na Transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component titulo="Titulo da vaga" id="inputTitulo" id-help="tituloHelp"
                texto-ajuda="Titulo da vaga">
                <hr />
                <input type="text" class="form-control" name="titulo" id="inputTitulo" aria-describedby="tituloHelp"
                  disabled :value="$store.state.item.titulo" />
              </input-container-component>
              <input-container-component titulo="Data de encerramento" id="inputEncerramento" id-help="EncerramentoHelp"
                texto-ajuda="Data do encerramento">
                <hr />
                <p>{{ $store.state.item.programacaoDateRemove }}</p>
              </input-container-component>
            </div>
          </div>
          <div class="col mb-3">
            <div class="form-group">
              <img v-if="$store.state.item.imagem != null" :src="'/storage/' + $store.state.item.imagem" width="200"
                height="400" />
              <img v-else src="perfil_padrao.jpg" width="200" height="400" />
              <!-- </input-container-component> -->
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-danger" @click="remover()" v-if="$store.state.transacao.status != 'sucesso'">
          Remover
        </button>
      </template>
    </modal-component>
    <!-- ########################################## -->
    <!-- Inicio modal atualizar vagas -->
    <modal-component id="modalVagasEdit" titulo="Atualizar vaga">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component titulo="Titulo da vaga" id="inputTitulo" id-help="tituloHelp"
                texto-ajuda="Titulo da vaga">
                <hr />
                <input type="text" class="form-control" name="titulo" id="inputTitulo" aria-describedby="tituloHelp"
                  v-model="$store.state.item.titulo" />
              </input-container-component>
              <input-container-component titulo="Agendar data de encerramento" id="inputEncerramento"
                id-help="EncerramentoHelp" texto-ajuda="Informe a data do encerramento">
                <hr />
                <input type="date" class="form-control" name="programacao" id="inputEncerramento"
                  aria-describedby="EncerramentoHelp" :min="dataDay" v-model="$store.state.item.programacao" />
              </input-container-component>
            </div>
          </div>
          <div class="col mb-3">
            <div class="form-group">
              <div id="preview">
                <img v-if="urlPreview" :src="urlPreview" width="150" height="200" />
                <img v-if="$store.state.item.imagem != null && urlPreview == ''"
                  :src="'/storage/' + $store.state.item.imagem" width="150" height="200" />
              </div>
              <input-container-component titulo="Selecione a imagem" id="inputImagem" id-help="imagemHelp"
                texto-ajuda="Selecione a imagem">
                <hr />
                <input type="file" name="imagem" class="form-control" id="inputImagem" aria-describedby="imagemHelp"
                  placeholder="Selecione uma imagem" @change="carregarImagem($event)" />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="atualizar()">
          Atualizar
        </button>
      </template>
    </modal-component>
    <!-- Fim modal adiocionar vagas -->
    <!-- ########################################## -->
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      urlBase: "https://portalcolaborador.candido.org.br/api/v1/vaga_interna",
      programacao: "",
      imagem: [],
      transacaoStatus: "",
      transacaoDetalhes: {},
      vagas: [],
      titulo: "",
      dataDay: "",
      urlPreview: "",
    };
  },
  methods: {
    resetModal() {
      this.titulo = "";
      this.programacao = "";
      this.imagem = [];
      this.urlPreview = "";
      inputImagem[0].value = "";
    },
    atualizar() {
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("titulo", this.$store.state.item.titulo);
      formData.append("programacao", this.$store.state.item.programacao);
      if (this.imagem[0] != null && this.imagem[0] != undefined) {
        formData.append("imagem", this.imagem[0]);
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      let url = this.urlBase + "/" + this.$store.state.item.id;

      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg)
          this.carregarVagas();
        })
        .catch((errors) => {
          this.setMessage("erro", errors.response.data.errors, errors.response.data.message)
        });
    },
    remover() {
      let confirmacao = confirm("Confirmar remoção do registro?");
      if (!confirmacao) return false;
      let url = this.urlBase + "/" + this.$store.state.item.id;
      let formData = new FormData();
      formData.append("_method", "delete");
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg)
          this.carregarVagas();
        })
        .catch((errors) => {
          this.setMessage("erro", errors.response.data.errors, errors.response.data.message)
        });
    },
    setStore(obj) {
      this.resetModal();
      this.$store.state.item = obj;
      this.$store.state.item.programacao = obj.programacao.split(" ")[0];
      this.$store.state.item.programacaoDateRemove = new Date(
        obj.programacao
      ).toLocaleDateString("en-GB");
    },
    carregaDataDay() {
      let dt = new Date();
      this.dataDay =
        dt.getFullYear() +
        "-" +
        ("0" + (dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dt.getDate()).slice(-2);
    },
    carregarVagas() {
      axios
        .get(this.urlBase)
        .then((response) => {
          this.vagas = response.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    carregarImagem(e) {
      this.imagem = e.target.files;
      this.urlPreview = URL.createObjectURL(this.imagem[0]);
    },
    salvar() {
      // console.log(this.programacao+'_=_'+this.imagem[0])
      let formData = new FormData();
      formData.append("titulo", this.titulo);
      formData.append("programacao", this.programacao);
      formData.append("imagem", this.imagem[0]);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios
        .post(this.urlBase, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg)
          this.carregarVagas();
          this.resetModal()
        })
        .catch((errors) => {
          this.setMessage("erro", errors.response.data.errors, errors.response.data.message)
        });
    },
  },
  mounted() {
    this.carregarVagas();
    this.carregaDataDay();
  },
};
</script>
