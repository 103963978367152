<template>
  <div class="container">
    <div class="row justify-content-center">
      <h2 class="text-center">Membros da CIPA</h2>
      <div class="col-md-10 mt-5">
        <card-component titulo="Lista de Membros da CIPA">
          <template v-slot:btnAdd v-if="user == 'adm' || user == 'cipa'">
            <button
              type="submit"
              class="btn btn-primary btn-sm btnAdd"
              data-bs-toggle="modal"
              data-bs-target="#modalCipaAdicionar"
              @click="resetModal"
            >
              Cadastrar Membro da CIPA
            </button>
          </template>
          <template v-slot:emailCipa>
            <b>E-mail:</b> {{ email }}
            <button
              type="submit"
              class="btn btn-primary btn-sm btnAdd"
              data-bs-toggle="modal"
              data-bs-target="#modalContatoCipaAtualizar"
              @click="resetModal"
              v-if="user == 'adm' || user == 'cipa'"
            >
              Editar E-mail
            </button>
          </template>
          <template v-slot:conteudo>
            <table-component
              style="position: relative"
              :dados="membros_cipa"
              :btnAtualizar="{
                visivel: user == 'adm' || user == 'cipa' ? true : false,
                dataToggle: 'modal',
                dataTarget: '#modalCipaAtualizar',
              }"
              :btnRemover="{
                visivel: user == 'adm' || user == 'cipa' ? true : false,
                dataToggle: 'modal',
                dataTarget: '#modalCipaRemover',
              }"
              :titulos="{
                id: { titulo: '#', tipo: 'texto' },
                nome: { titulo: 'Nome', tipo: 'texto' },
                setor: { titulo: 'Setor', tipo: 'texto' },
              }"
            ></table-component>
          </template>
        </card-component>
      </div>
    </div>
    <modal-component id="modalCipaAdicionar" titulo="Cadastrar membro CIPA">
      <template v-slot:alertas>
        <alert-component
          mensagem="Transação realizada com sucesso"
          tipo="success"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"
        ></alert-component>
        <alert-component
          mensagem="Erro na transação"
          tipo="danger"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"
        ></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="Nome"
                id="inputNome"
                id-help="nomeHelp"
                texto-ajuda="Informe o nome"
              >
                <input
                  type="text"
                  class="form-control"
                  name="nome"
                  id="inputNome"
                  aria-describedby="nomeHelp"
                  placeholder="Informe o nome"
                  v-model="nome"
                />
              </input-container-component>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="Setor"
                id="inputSetor"
                id-help="setorHelp"
                texto-ajuda="Informe o setor"
              >
                <input
                  type="text"
                  class="form-control"
                  name="nome"
                  id="inputSetor"
                  aria-describedby="setorHelp"
                  placeholder="Informe o setor"
                  v-model="setor"
                />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="adicionar()">Salvar</button>
      </template>
    </modal-component>
    <modal-component id="modalCipaAtualizar" titulo="Atualizar membro CIPA">
      <template v-slot:alertas>
        <alert-component
          mensagem="Transação realizada com sucesso"
          tipo="success"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"
        ></alert-component>
        <alert-component
          mensagem="Erro na transação"
          tipo="danger"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"
        ></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="Nome"
                id="inputNome"
                id-help="nomeHelp"
                texto-ajuda="Informe o nome"
              >
                <input
                  type="text"
                  class="form-control"
                  name="nome"
                  id="inputNome"
                  aria-describedby="nomeHelp"
                  placeholder="Informe o nome"
                  v-model="$store.state.item.nome"
                />
              </input-container-component>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="Setor"
                id="inputSetor"
                id-help="setorHelp"
                texto-ajuda="Informe o setor"
              >
                <input
                  type="text"
                  class="form-control"
                  name="nome"
                  id="inputSetor"
                  aria-describedby="setorHelp"
                  placeholder="Informe o setor"
                  v-model="$store.state.item.setor"
                />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="atualizar()">Salvar</button>
      </template>
    </modal-component>
    <modal-component id="modalCipaRemover" titulo="Remover membro CIPA">
      <template v-slot:alertas>
        <alert-component
          mensagem="Transação realizada com sucesso"
          tipo="success"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"
        ></alert-component>
        <alert-component 
          mensagem="Erro na transação"
          tipo="danger"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"
        ></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="Nome"
                id="inputNome"
                id-help="nomeHelp"
                texto-ajuda="Informe o nome"
              >
                <input
                  type="text"
                  class="form-control"
                  name="nome"
                  id="inputNome"
                  aria-describedby="nomeHelp"
                  disabled
                  v-model="$store.state.item.nome"
                />
              </input-container-component>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="Setor"
                id="inputSetor"
                id-help="setorHelp"
                texto-ajuda="Informe o setor"
              >
                <input
                  type="text"
                  class="form-control"
                  name="nome"
                  id="inputSetor"
                  aria-describedby="setorHelp"
                  disabled
                  v-model="$store.state.item.setor"
                />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="remover()"
          v-if="$store.state.transacao.status != 'sucesso'"
        >
          Remover
        </button>
      </template>
    </modal-component>
    <modal-component id="modalContatoCipaAtualizar" titulo="Editar contato CIPA">
      <template v-slot:alertas>
        <alert-component
          mensagem="Transação realizada com sucesso"
          tipo="success"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"
        ></alert-component>
        <alert-component
          mensagem="Erro na transação"
          tipo="danger"
          :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"
        ></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component
                titulo="E-mail"
                id="inputEmail"
                id-help="emailHelp"
                texto-ajuda="Informe o e-mail"
              >
                <input
                  type="text"
                  class="form-control"
                  name="nome"
                  id="inputEmail"
                  aria-describedby="emailHelp"
                  v-model="email"
                />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="updateContatoCipa()"
          v-if="$store.state.transacao.status != 'sucesso'"
        >
          Atualizar
        </button>
      </template>
    </modal-component>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      urlBase: "https://portalcolaborador.candido.org.br/api/v1/cipa",
      nome: "",
      email: "",
      setor: "",
      membros_cipa: [],
    };
  },
  methods: {
    resetModal() {
      this.nome = "";
      // this.email = "";
      this.setor = "";
    },
    remover() {
      let confirmacao = confirm("Confirmar remoção do registro?");
      if (!confirmacao) return false;
      let url = this.urlBase + "/" + this.$store.state.item.id;
      let formData = new FormData();
      formData.append("_method", "delete");
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarMembros();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    atualizar() {
      let url = this.urlBase + "/" + this.$store.state.item.id;
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("nome", this.$store.state.item.nome);
      // formData.append("email", this.$store.state.item.email);
      formData.append("setor", this.$store.state.item.setor);
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarMembros();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    adicionar() {
      let formData = new FormData();
      formData.append("nome", this.nome);
      // formData.append("email", this.email);
      formData.append("setor", this.setor);

      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios
        .post(this.urlBase, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarMembros();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    carregarMembros() {
      axios
        .get(this.urlBase)
        .then((response) => {
          this.membros_cipa = response.data;
        })
        .catch((errors) => {});
    },
    updateContatoCipa() {
      let formData = new FormData();
      // formData.append("_method", "patch");
      formData.append("email", this.email);
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post("https://portalcolaborador.candido.org.br/api/v1/contato_cipa", formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarContatoCipa();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    carregarContatoCipa() {
      axios
        .get("https://portalcolaborador.candido.org.br/api/v1/contato_cipa")
        .then((response) => {
          this.email = response.data.email;
        })
        .catch((errors) => {});
    },
  },
  mounted() {
    this.carregarMembros();
    this.carregarContatoCipa();
  },
};
</script>
<style>
.btnAdd {
  width: 20%;
}
@media screen and (max-width: 450px) {
  .btnAdd {
    width: 50%;
  }
  .table tr {
    text-transform: uppercase;
    word-wrap: break-word;
    word-break: break-word;
  }
}
</style>
