<template>
  <div class="container">
    <div style="text-align: center; margin: 0 20px 20px 20px" class="titulo">
      <h3>Aniversariantes do mês</h3>
      <h4>
        Desejamos a todos um feliz Aniversário, agradecemos pela sua dedicação!!
      </h4>
    </div>
    <!-- https://codepen.io/PepitaK/pen/mdqpRee -->
    <div class="calendario">
      <h2>{{ mesNow }} {{ fullYear }}</h2>

      <ul>
        <li v-for="dia in diasDoMes" :key="dia" :class="dia == now.getDate() ? 'today' : ''">
          <div :id="'ani-day' + dia" class="white-popup mfp-hide calendario-view">
            <div v-if="new Date(user.nascimento).getDate() == dia &&
              new Date(user.nascimento).getMonth() + 1 == now.getMonth() + 1
              " v-for="user in usuarios" :key="user.id" class="li-ani-view">
              <img v-if="user.imagem != null && user.imagem != 'NULL' && user.imagem != 'null'" :src="'/storage/' + user.imagem" style="border-radius: 10px" />
              <img v-else src="perfil_padrao.jpg" style="border-radius: 10px; object-fit: cover;" />
              <span>{{ user.name }}</span>
            </div>
          </div>
          <a :href="'#ani-day' + dia" class="open-popup-ani link-ani">
            <time :datetime="fullYear + '-' + (now.getMonth() + 1) + '-' + dia">{{
              dia
            }}</time>
          </a>
          <span v-if="new Date(user.nascimento).getDate() == dia &&
              new Date(user.nascimento).getMonth() + 1 == now.getMonth() + 1
              " v-for="user in usuarios" :key="user.id" class="li-ani">
            <img v-if="user.imagem != null && user.imagem != 'NULL' && user.imagem != 'null'" :src="'/storage/' + user.imagem" class="li-imagem"
              style="border-radius: 10px" />
            <img v-else src="perfil_padrao.jpg" class="li-imagem" style="border-radius: 10px; object-fit: cover;" />
            <span class="li-nome">{{ user.name }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      now: "",
      fullYear: "",
      mesNow: "",
      meses: {
        Janeiro: 1,
        Fevereiro: 2,
        Marco: 3,
        Abril: 4,
        Maio: 5,
        Junho: 6,
        Julho: 7,
        Agosto: 8,
        Setembro: 9,
        Outubro: 10,
        Novembro: 11,
        Dezembro: 12,
      },
      diasDoMes: "",
      dateTime: "",
      usuarios: {},
    };
  },
  methods: {
    dateNow() {
      this.now = new Date();
      this.fullYear = this.now.getFullYear();
      this.diasDoMes = new Date(
        this.fullYear,
        this.now.getMonth() + 1,
        0
      ).getDate();
      Object.keys(this.meses).forEach((mes) => {
        if (this.now.getMonth() + 1 === this.meses[mes]) {
          this.mesNow = mes;
        }
      });
      this.dateTime =
        this.fullYear +
        "-" +
        (this.now.getMonth() + 1) +
        "-" +
        this.now.getDate();
    },
    getUsuarios() {
      axios
        .get("https://portalcolaborador.candido.org.br/api/v1/usuarios")
        .then((response) => {
          this.usuarios = response.data;
          $(".open-popup-ani").magnificPopup({
            type: "inline",
            //   midClick: true,
            //   closeOnBgClick: true
            //   closeBtnInside: true,
            //   showCloseBtn: true
            //   closeOnContentClick: true
            //   alignTop: true
          }); 
          console.log(response.data)
        })
        .catch((errors) => {
          console.log(errors.response);
        });
    },
  },
  mounted() {
    this.dateNow();
    this.getUsuarios();
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;800&display=swap");

.calendario {
  display: flex;
  flex-flow: column;
  align-items: center;
  font-family: "Poppins", serif;
  background: #eeaeca;
  background: radial-gradient(circle, #eeaeca 0%, #94bbe9 100%);
  border-radius: 10px;
}

.calendario-view {
  display: flex;
  width: 700px;
  height: 600px;
  margin: 0 auto;
  flex-flow: column;
  align-items: center;
  font-family: "Poppins", serif;
  background: #eeaeca;
  background: radial-gradient(circle, #eeaeca 0%, #94bbe9 100%);
  border-radius: 10px;
  overflow: auto;
  overflow-x: hidden;
}

.calendario li::-webkit-scrollbar-track {
  background-color: #dad7d7;
  /* border-radius: 10px; */
}

.calendario li::-webkit-scrollbar {
  width: 0px;
  background: #dad7d7;
  /* border-radius: 10px; */
}

.calendario li::-webkit-scrollbar-thumb {
  background: #f4f4f4;
  /* border-radius: 10px; */
}

.calendario h2 {
  font-weight: 700;
  margin: 0.5rem 0 1.5rem 0;
}

.calendario ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  list-style: none;
  place-items: center;
}

.calendario ul li {
  display: flex;
  width: 12rem;
  height: 15rem;
  margin: 0.25rem;
  flex-flow: column;
  border-radius: 0.2rem;
  padding: 0.5rem;
  font-weight: 400;
  font-size: 0.5rem;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow-x: hidden;
}

.calendario ul li time {
  font-size: 2rem;
  /* margin: 0 0 1rem 0; */
  font-weight: 500;
}

.calendario ul .today {
  background: #ffffff70;
}

.calendario ul .today time {
  font-weight: 800;
}

.li-ani {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.li-nome {
  padding: 0;
  margin: 5px 0;
  font-size: 1rem;
  text-align: center;
}

.li-imagem {
  width: 80px;
  /* height: 80px; */
}

.link-ani {
  text-decoration: none !important;
  background: radial-gradient(circle, #eeaeca59 0%, #94bce957 100%);
  margin-bottom: 5px;
  border-radius: 20px;
  width: 40px;
  text-align: center;
}

.link-ani:hover {
  color: #000;
}

.li-ani-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 10px;
  font-size: 1.5rem;
}

.li-ani-view img {
  width: 150px;
  height: 150px;
}

@media screen and (max-width: 450px) {
  .container {
    padding: 0 calc(var(--bs-gutter-x) * 0.3);
  }

  .calendario ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .calendario ul li {
    display: flex;
    width: 5rem;
    height: 7.5rem;
    margin: 0.25rem;
    flex-flow: column;
    border-radius: 0.2rem;
    padding: 0.2rem;
    font-weight: 600;
    font-size: 0.6rem;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 16px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    overflow-x: hidden;
  }

  .calendario ul li time {
    font-size: 1rem;
    /* margin: 0 0 1rem 0; */
    font-weight: 500;
  }

  .calendario ul .today {
    background: #ffffff70;
  }

  .calendario ul .today time {
    font-weight: 800;
  }

  .li-nome {
    padding: 0;
    margin: 3px 0;
    font-size: 0.5rem;
    text-align: center;
  }

  .li-imagem {
    width: 60%;
    /* height: 30px; */
  }

  .titulo h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .titulo h4 {
    font-size: 1rem;
  }

  .calendario-view {
    width: auto;
    /* height: 600px; */
  }

  .li-ani-view img {
    width: 150px;
    /* height: 100px; */
  }

  .li-ani-view {
  font-size: 1rem;
}
}
</style>