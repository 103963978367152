<template>
  <table class="table table-hover" style="text-align: center">
    <thead>
      <tr>
        <th scope="col" v-for="(t, key) in titulos" :key="key" v-if="t.titulo != '#'">
          {{ t.titulo }}
        </th>
        <th v-if="btnAtualizar.visivel || btnRemover.visivel"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(obj, chave) in dadosFiltrados" :key="chave">
        <td v-for="(valor, chaveValor) in obj" :key="chaveValor" v-if="titulos[chaveValor].titulo != '#'">
          <span v-if="titulos[chaveValor].tipo == 'imagem'">
            <img
              v-if="valor != null && valor != 'NULL' && valor != 'null'" 
              :src="'/storage/' + valor"
              width="40"
              height="40"
              style="border-radius: 50%; object-fit: cover;"
            />
            <img v-else src="perfil_padrao.jpg" width="40" height="40" style="border-radius: 50%; object-fit: cover;"/>
          </span>
          <span v-if="titulos[chaveValor].tipo == 'texto'">{{ valor }}</span>
          <span v-if="titulos[chaveValor].tipo == 'date'">{{valor | formataDataGlobal}}</span>
        </td>
        <td v-if="btnAtualizar.visivel || btnRemover.visivel">
          <button
            v-if="btnAtualizar.visivel"
            class="btn btn-outline-primary btn-sm"
            :data-bs-toggle="btnAtualizar.dataToggle"
            :data-bs-target="btnAtualizar.dataTarget"
            @click="setStore(obj)"
          >
            Editar
          </button>
          <button
            v-if="btnRemover.visivel"
            class="btn btn-outline-danger btn-sm"
            :data-bs-toggle="btnRemover.dataToggle"
            :data-bs-target="btnRemover.dataTarget"
            @click="setStore(obj)"
          >
            Remover
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["dados", "titulos", "btnAtualizar", "btnRemover"],
  methods: {
    setStore(obj) {
      this.$store.state.item = obj;
      this.$store.state.transacao.status = "";
      this.$store.state.transacao.mensagem = "";
    },
  },
  computed: {
    dadosFiltrados() {
      let campos = Object.keys(this.titulos);
      // console.log(Date.parse(new Date()))
      let dadosFiltrados = [];
      this.dados.map((item, chave) => {
        let itemFiltrado = {};
        campos.forEach((campo) => {
          itemFiltrado[campo] = item[campo];
          if (campo == "permissao_cesta") {
            item["permissao_cesta"] == 0
              ? (itemFiltrado[campo] = "Inativo")
              : (itemFiltrado[campo] = "Ativo");
          }
          if (campo == "tipo_cesta") {
            if (item["tipo_cesta"] == 0) {
              itemFiltrado[campo] = "A"
            } 
            if (item["tipo_cesta"] == 1) {
              itemFiltrado[campo] = "B"
            } 
            if (item["tipo_cesta"] == 2) {
              itemFiltrado[campo] = "Não selecionado"
            }
          }
          if (campo == "nascimento") {
            itemFiltrado["nascimento"] = item[campo].split(" ")[0];
          }
        });
        dadosFiltrados.push(itemFiltrado);
      });
      return dadosFiltrados;
    },
  },
}; 
</script>
<style>

@media screen and (max-width: 450px) {
  .table {
    font-size: 0.6em;
    
  }
  .table tr {
    max-width: 100%;
    word-break: break-all;
  }

  .btn {
    font-size: 0.8em;
  }
}
</style>